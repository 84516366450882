import api from "../api.service";

class EmployeeNationalContractService {
  get PATH() {
    return "employee-nationalcontract";
  }

  async getAll({
    perPage,
    page,
    sortBy = null,
    search = null,
    sortDesc = null,
    fields = null,
    expand = null,
    omit = null
  }) {
    const offset = (page - 1) * perPage;
    const params = {
      limit: perPage,
      offset,
      search
    }
    if (sortBy) {
      params.ordering = sortBy;
    }
    if (sortDesc && sortBy) {
      sortBy = "-" + sortBy;
      params.ordering = sortBy;
    }
    if (omit && omit.length) {
      params.omit = omit;
    }
    if (fields && fields.length) {
      params.fields = fields;
    }
    if (expand && expand.length) {
      params.expand = expand;
    }
    const res = await api.get(`${this.PATH}/`, {
      params
    });
    return res.data;
  }

  async getOne(id) {
    const res = await api.get(`${this.PATH}/${id}/`);
    return res.data;
  }

  async getAllNotPaginated({ fields = null, expand = null, omit = null }) {
    const params = {};
    if (omit && omit.length) {
      params.omit = omit;
    }
    if (fields && fields.length) {
      params.fields = fields;
    }
    if (expand && expand.length) {
      params.expand = expand;
    }
    // use getAll with offset 0 and limit 1 to get the count with fields: id
    const count_res = await this.getAll({
      perPage: 1,
      page: 1,
      fields: "id"
    });
    return await this.getAll({
      perPage: count_res?.count || 1000,
      page: 1,
      fields,
      expand,
      omit

    })
  }

  async getAllNotPaginatedByCountry(countryIso) {
    const count = await api.get(`${this.PATH}/`);
    const res = await api.get(`${this.PATH}/`, {
      params: {
        limit: count.data.count
      }
    });
    return res.data.results.filter(el => el.country == countryIso);
  }

  /**
   * Creates a National Contract.
   * @param {*} item National Contract.
   */
  async create(item) {
    const res = await api.post(`${this.PATH}/`, item);
    return res.data;
  }

  /**
   * Updates a National Contract.
   * @param {*} item National Contract.
   * @param {*} id National Contract id.
   */
  async update(item, id) {
    const res = await api.patch(`${this.PATH}/${id}/`, item);
    return res.data;
  }

  /**
   * Deletes a National Contract.
   * @param {*} id National Contract ID
   */
  async delete(id) {
    await api.delete(`${this.PATH}/${id}/`);
  }
}

export default new EmployeeNationalContractService();