import api from "../api.service";

class EmployeeNationalContractLevelService {
  get PATH() {
    return "employee-nationalcontract-level";
  }

  async getAllByNationalContract(nationalContractID) {
    const count = await api.get(`${this.PATH}/`);
    const res = await api.get(`${this.PATH}/`, { params: { limit: count.data.count } });
    return res.data.results.filter(el => el.nationalcontract == nationalContractID);
  }

  async getByNationalContractPaginated(nationalContractID, {country, perPage, page, sortBy = null, search = null, sortDesc = null }) {
    const offset = (page - 1) * perPage;
    const params = { nationalcontract: nationalContractID, nationalcontract__country: country, limit: perPage, offset, search }
    if (sortBy) {
      params.ordering = sortBy;
    }
    if (sortDesc && sortBy) {
      sortBy = "-" + sortBy;
      params.ordering = sortBy;
    }

    const res = await api.get(`${this.PATH}/`, {
      params,
    });
    return res.data;
  }

  async getOne(id) {
    const res = await api.get(`${this.PATH}/${id}/`);
    return res.data;
  }

  async create(item) {
    const res = await api.post(`${this.PATH}/`, item);
    return res.data;
  }

  async update(item, id) {
    const res = await api.patch(`${this.PATH}/${id}/`, item);
    return res.data;
  }

  async delete(levelID) {
    await api.delete(`${this.PATH}/${levelID}/`);
  }
}

export default new EmployeeNationalContractLevelService();
