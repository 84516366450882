<template>
  <div class="form row">
    <div class="col-6">
      <euro-card no-header>
        <label class="h6 mb-4" for="nation-select">{{ $t("Nation") }}:</label>
        <euro-select id="nation-select" v-model="selectedCountryIso"
          :multiselect-props="countrySelectProps"></euro-select>
        <div class="separator separator-dashed mb-5"></div>
        <h6 class="mb-4">{{ $t("National contracts") }}</h6>
        <b-list-group>
          <b-list-group-item v-for="nationalContract in selectedCountryNationalContracts" :key="nationalContract.id"
            button :active="selectedNationalContract?.id === nationalContract.id"
            @click="onNationalContractClick(nationalContract)">
            {{ nationalContract.name }}
          </b-list-group-item>
        </b-list-group>
      </euro-card>
    </div>
    <div class="col-6">
      <euro-card no-header>
        <h6 class="mb-4">{{ $t("National contract levels") }}</h6>
        <b-list-group role="tablist">
          <template v-for="nationalContractLevel in selectedNationalContractsLevels">
            <b-list-group-item :key="`btn-${nationalContractLevel.id}`"
              v-b-toggle="`accordion-${nationalContractLevel.id}`" button
              class="d-flex justify-content-between align-items-center" role="tab">
              <div>
                <div>{{ levelHumanReadableName(nationalContractLevel) }}</div>
                <small>
                  {{ nationalContractLevel.corresponding_levels_details.length }} {{ $t("corresponding levels") }}
                </small>
              </div>
              <i :class="icons.caretDown"></i>
            </b-list-group-item>
            <b-collapse :id="`accordion-${nationalContractLevel.id}`" :key="`accordion-${nationalContractLevel.id}`"
              class="mb-5">
              <b-list-group>
                <b-list-group-item v-for="correspondingLevel in nationalContractLevel.corresponding_levels_details"
                  :key="correspondingLevel.id">
                  <div class="d-flex align-items-center gap-1">
                    <country-flag :country-iso="correspondingLevel.country"></country-flag>
                    {{ correspondingLevel.contract_name }}
                  </div>
                  <small class="font-weight-bolder">
                    <span>
                      {{ correspondingLevel.level_name }}
                    </span>
                    -
                    <span class="text-danger" :class="{
          'text-success': correspondingLevel.pay_per_hour_difference_percent < 0,
        }">
                      {{ correspondingLevel.pay_per_hour_difference_formatted }}
                    </span>
                    -
                    <span class="text-danger" :class="{
          'text-success': correspondingLevel.pay_per_hour_difference_percent < 0,
        }">
                      {{ correspondingLevel.pay_per_hour_difference_percent }}%
                    </span>
                  </small>
                </b-list-group-item>
              </b-list-group>
            </b-collapse>
          </template>
        </b-list-group>
      </euro-card>
    </div>
  </div>
</template>

<script>
import EuroCard from "@/view/components/cards/EuroCard.vue";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { getAllNationalContracts } from "@/core/abstractions/contracts";
import { backendErrorSwal } from "@/core/helpers/swal";
import { mapGetters } from "vuex";
import icons from "@/core/config/icons";
import CountryFlag from "@/view/components/CountryFlag.vue";
export default {
  components: {
    EuroCard,
    CountryFlag,
  },

  data() {
    return {
      icons,
      contracts: [],
      selectedCountryIso: null,
      selectedNationalContract: null,
    };
  },

  computed: {
    ...mapGetters("constants", ["countriesMap"]),

    contractsCountriesIso() {
      return [...new Set(this.contracts.map(contract => contract.country))];
    },

    countrySelectProps() {
      return {
        options: this.contractsCountriesIso.map(countryIso => ({
          iso: countryIso,
          name: this.countriesMap[countryIso]?.name,
        })),
        label: "name",
        "track-by": "iso",
        placeholder: this.$t("Select a country"),
      };
    },

    selectedCountryNationalContracts() {
      return this.contracts.filter(nationalContract => nationalContract.country == this.selectedCountryIso);
    },

    selectedNationalContractsLevels() {
      if (!this.selectedNationalContract) return [];

      return this.selectedNationalContract.nationalcontractlevel_set;
    },
  },

  watch: {
    selectedCountryIso() {
      this.selectedNationalContract = null;
    },
  },

  async mounted() {
    await this.$store.dispatch(SET_BREADCRUMB, [{ title: this.$t("Contracts comparison") }]);
    try {
      this.contracts = await getAllNationalContracts();
    } catch (err) {
      await backendErrorSwal(err);
    }
  },

  methods: {
    levelHumanReadableName(level) {
      return `${level.level_name} - ${level.base_pay} ${level.base_pay_currency}/h`;
    },

    onNationalContractClick(nationalContract) {
      this.selectedNationalContract = nationalContract;
    },
  },
};
</script>

<style lang="scss" scoped>
.collapsed {
  & i {
    transform: rotate(0deg);
    transition: transform 0.2s linear;
  }
}

.not-collapsed {
  & i {
    transform: rotate(180deg);
    transition: transform 0.2s linear;
  }
}

.gap-1 {
  gap: 1rem;
}
</style>
