var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "card card-custom"
  }, [!_vm.noHeader ? _c('div', {
    staticClass: "card-header border-0 pt-6 pb-0"
  }, [_c('div', {
    staticClass: "card-title align-items-start flex-column"
  }, [_c('h3', {
    staticClass: "card-label font-weight-bolder"
  }, [_vm._v(" " + _vm._s(_vm.title) + " ")]), _vm.subtitle ? _c('div', {
    staticClass: "text-muted font-weight-bold font-size-sm mt-1"
  }, [_vm._v(" " + _vm._s(_vm.subtitle) + " ")]) : _vm._e()]), _vm.footer ? _c('div', {
    staticClass: "card-toolbar"
  }, [_vm._t("footer")], 2) : _vm._e()]) : _vm._e(), _c('div', {
    staticClass: "card-body",
    class: _vm.bodyClasses
  }, [_vm._t("default")], 2)]);

}
var staticRenderFns = []

export { render, staticRenderFns }