<template>
  <div class="card card-custom">
    <div v-if="!noHeader" class="card-header border-0 pt-6 pb-0">
      <div class="card-title align-items-start flex-column">
        <h3 class="card-label font-weight-bolder">
          {{ title }}
        </h3>
        <div v-if="subtitle" class="text-muted font-weight-bold font-size-sm mt-1">
          {{ subtitle }}
        </div>
      </div>
      <div v-if="footer" class="card-toolbar">
        <slot name="footer"></slot>
      </div>
    </div>
    <div class="card-body" :class="bodyClasses">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: "",
    },
    subtitle: {
      type: String,
      default: "",
    },
    icon: {
      type: String,
      default: "",
    },
    noHeader: {
      type: Boolean,
      default: false,
    },
    bodyClasses: {
      type: [String, Array],
      default: "",
    },
    footer: {
      type: Boolean,
      default: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.gap-1 {
  gap: 0.2rem;
}
</style>
