var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "form row"
  }, [_c('div', {
    staticClass: "col-6"
  }, [_c('euro-card', {
    attrs: {
      "no-header": ""
    }
  }, [_c('label', {
    staticClass: "h6 mb-4",
    attrs: {
      "for": "nation-select"
    }
  }, [_vm._v(_vm._s(_vm.$t("Nation")) + ":")]), _c('euro-select', {
    attrs: {
      "id": "nation-select",
      "multiselect-props": _vm.countrySelectProps
    },
    model: {
      value: _vm.selectedCountryIso,
      callback: function callback($$v) {
        _vm.selectedCountryIso = $$v;
      },
      expression: "selectedCountryIso"
    }
  }), _c('div', {
    staticClass: "separator separator-dashed mb-5"
  }), _c('h6', {
    staticClass: "mb-4"
  }, [_vm._v(_vm._s(_vm.$t("National contracts")))]), _c('b-list-group', _vm._l(_vm.selectedCountryNationalContracts, function (nationalContract) {
    var _vm$selectedNationalC;
    return _c('b-list-group-item', {
      key: nationalContract.id,
      attrs: {
        "button": "",
        "active": ((_vm$selectedNationalC = _vm.selectedNationalContract) === null || _vm$selectedNationalC === void 0 ? void 0 : _vm$selectedNationalC.id) === nationalContract.id
      },
      on: {
        "click": function click($event) {
          return _vm.onNationalContractClick(nationalContract);
        }
      }
    }, [_vm._v(" " + _vm._s(nationalContract.name) + " ")]);
  }), 1)], 1)], 1), _c('div', {
    staticClass: "col-6"
  }, [_c('euro-card', {
    attrs: {
      "no-header": ""
    }
  }, [_c('h6', {
    staticClass: "mb-4"
  }, [_vm._v(_vm._s(_vm.$t("National contract levels")))]), _c('b-list-group', {
    attrs: {
      "role": "tablist"
    }
  }, [_vm._l(_vm.selectedNationalContractsLevels, function (nationalContractLevel) {
    return [_c('b-list-group-item', {
      directives: [{
        name: "b-toggle",
        rawName: "v-b-toggle",
        value: "accordion-".concat(nationalContractLevel.id),
        expression: "`accordion-${nationalContractLevel.id}`"
      }],
      key: "btn-".concat(nationalContractLevel.id),
      staticClass: "d-flex justify-content-between align-items-center",
      attrs: {
        "button": "",
        "role": "tab"
      }
    }, [_c('div', [_c('div', [_vm._v(_vm._s(_vm.levelHumanReadableName(nationalContractLevel)))]), _c('small', [_vm._v(" " + _vm._s(nationalContractLevel.corresponding_levels_details.length) + " " + _vm._s(_vm.$t("corresponding levels")) + " ")])]), _c('i', {
      class: _vm.icons.caretDown
    })]), _c('b-collapse', {
      key: "accordion-".concat(nationalContractLevel.id),
      staticClass: "mb-5",
      attrs: {
        "id": "accordion-".concat(nationalContractLevel.id)
      }
    }, [_c('b-list-group', _vm._l(nationalContractLevel.corresponding_levels_details, function (correspondingLevel) {
      return _c('b-list-group-item', {
        key: correspondingLevel.id
      }, [_c('div', {
        staticClass: "d-flex align-items-center gap-1"
      }, [_c('country-flag', {
        attrs: {
          "country-iso": correspondingLevel.country
        }
      }), _vm._v(" " + _vm._s(correspondingLevel.contract_name) + " ")], 1), _c('small', {
        staticClass: "font-weight-bolder"
      }, [_c('span', [_vm._v(" " + _vm._s(correspondingLevel.level_name) + " ")]), _vm._v(" - "), _c('span', {
        staticClass: "text-danger",
        class: {
          'text-success': correspondingLevel.pay_per_hour_difference_percent < 0
        }
      }, [_vm._v(" " + _vm._s(correspondingLevel.pay_per_hour_difference_formatted) + " ")]), _vm._v(" - "), _c('span', {
        staticClass: "text-danger",
        class: {
          'text-success': correspondingLevel.pay_per_hour_difference_percent < 0
        }
      }, [_vm._v(" " + _vm._s(correspondingLevel.pay_per_hour_difference_percent) + "% ")])])]);
    }), 1)], 1)];
  })], 2)], 1)], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }